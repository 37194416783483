import Input from '@/interfaces/inputs/Input'
import FormElement from '@/interfaces/form/FormElement'

const linkedin: Input = {
  id: 'linkedin',
  label: 'Linkedin',
  type: 'text',
  value: null
}

const facebook: Input = {
  id: 'facebook',
  label: 'Facebook',
  type: 'text',
  value: null
}

const twitter: Input = {
  id: 'twitter',
  label: 'Twitter',
  type: 'text',
  value: null
}

const instagram: Input = {
  id: 'instagram',
  label: 'Instagram',
  type: 'text',
  value: null
}

const youtube: Input = {
  id: 'youtube',
  label: 'Youtube',
  type: 'text',
  value: null
}

const pinterest: Input = {
  id: 'pinterest',
  label: 'Pinterest',
  type: 'text',
  value: null
}

const patreon: Input = {
  id: 'patreon',
  label: 'Patreon',
  type: 'text',
  value: null
}

const behance: Input = {
  id: 'behance',
  label: 'Behance',
  type: 'text',
  value: null
}

const github: Input = {
  id: 'github',
  label: 'Github',
  type: 'text',
  value: null
}

const gitlab: Input = {
  id: 'gitlab',
  label: 'Gitlab',
  type: 'text',
  value: null
}

export const formElements: FormElement[] = [
  {
    type: 'input',
    data: linkedin
  },
  {
    type: 'input',
    data: facebook
  },
  {
    type: 'input',
    data: twitter
  },
  {
    type: 'input',
    data: instagram
  },
  {
    type: 'input',
    data: youtube
  },
  {
    type: 'input',
    data: pinterest
  },
  {
    type: 'input',
    data: patreon
  },
  {
    type: 'input',
    data: behance
  },
  {
    type: 'input',
    data: github
  },
  {
    type: 'input',
    data: gitlab
  }
]
