<template>
  <div class="p-4">
    <div class="mb-4">Mis redes sociales</div>
    <BaseForm
      :formElements="formElements"
      @form:onSubmit="value => updateSocial(value)"
      :isLoading="isLoading"
    >
      <template #elements>
        <BaseFormElement
          :key="key"
          v-for="(element, key) in formElements"
          :element="element"
          v-model="element.data.value"
        />
      </template>
    </BaseForm>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import BaseForm from '@/components/form/BaseForm.vue'
import BaseFormElement from '@/components/form/BaseFormElement.vue'
import { formElements as SocialNetworksFormElements } from '@/config/SocialNetworksFormElements.ts'
import syncValues from '@/utils/syncValues.ts'

// Social store
import { SOCIAL_NETWORKS_STORE_NAME } from '@/store/modules/socialNetworks'
import { UPDATE_SOCIAL_NETWORKS } from '@/store/modules/socialNetworks/actions.type'

// Notifications store
import { NOTIFICATIONS_STORE_NAME } from '@/store/modules/notifications'
import { PUSH_NOTIFICATION } from '@/store/modules/notifications/actions.type'

import { useStore } from 'vuex'
export default {
  components: {
    BaseForm,
    BaseFormElement
  },
  setup() {
    const store = useStore()
    const formElements = ref(SocialNetworksFormElements)
    const socialData = computed(
      () => store.getters[SOCIAL_NETWORKS_STORE_NAME + '/data']
    )
    const isLoading = computed(
      () => store.getters[SOCIAL_NETWORKS_STORE_NAME + '/isLoading']
    )
    const isError = computed(
      () => store.getters[SOCIAL_NETWORKS_STORE_NAME + '/isError']
    )
    const isReady = computed(
      () => store.getters[SOCIAL_NETWORKS_STORE_NAME + '/isReady']
    )

    watch(socialData, socialData => {
      if (socialData) {
        formElements.value = syncValues(formElements.value, socialData)
      }
    })
    if (socialData.value) {
      formElements.value = syncValues(formElements.value, socialData.value)
    }
    async function updateSocial(data) {
      await store.dispatch(
        SOCIAL_NETWORKS_STORE_NAME + '/' + UPDATE_SOCIAL_NETWORKS,
        data
      )
      if (isReady.value) {
        pushNotification({
          title: 'Aplicado correctamente!',
          desciption:
            'Tu informacion relacionada con Social se ha actualizado ',
          type: 'success'
        })
      }
      if (isError.value) {
        pushNotification({
          title: 'Algun error ha ocurrido!',
          desciption: 'Vuelve a intentarlo o contacta a soporte',
          type: 'error'
        })
      }
    }

    function pushNotification(data) {
      store.dispatch(NOTIFICATIONS_STORE_NAME + '/' + PUSH_NOTIFICATION, data)
    }

    return {
      isLoading,
      updateSocial,
      formElements
    }
  }
}
</script>
